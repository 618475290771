var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.table.headers,"items":_vm.table.items,"options":_vm.tableOptions,"server-items-length":_vm.table.totalItems,"loading":_vm.table.loading,"dense":""},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Master Kuesioner Link")]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return undefined}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","label":"Nama :"},model:{value:(_vm.form.editedItem.nama),callback:function ($$v) {_vm.$set(_vm.form.editedItem, "nama", $$v)},expression:"form.editedItem.nama"}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Simpan ")])],1)],1)],1)],1)]},proxy:true},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_vm._v(_vm._s(item.link))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return undefined}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.getDataFromApi}},[_vm._v(" Reload ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }